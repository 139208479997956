import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Screen from "../components/Screen"
import Intro from "../components/Intro"
import Body from "../components/Body"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Screen>
      <Intro>Not found</Intro>
      <Body>You just hit a route that doesn&#39;t exist... the sadness.</Body>
    </Screen>
  </Layout>
)

export default NotFoundPage
